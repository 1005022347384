import React, { useState } from 'react';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isChecked) {
      setResultMessage('Bitte akzeptieren Sie die Datenschutzbedingungen.');
      return;
    }

    
    const username = '1b89d9ce99025e91120e665ca298f6c63934f6cc';
    const password = '8791083a7db4c57a769340505d7db53206cc17ea'; 
    
    //const username = '344be5a1553e0ef102ead45ddc0ac9265413ad97';
    //const password = '9ac460202c45d9d21b7c0cad0db4cb18d2dfcaba'; 
    const url = 'https://apiv3.emailsys.net/mailings';

    const jsonData = {
      email: email,
      firstname: firstname,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        },
        body: JSON.stringify(jsonData)
      });

      if (!response.ok) {
        throw new Error('Netzwerkantwort war nicht ok');
      }

      const data = await response.json();

      if (data.success) {
        setResultMessage('Vielen Dank fürs Abonnieren!');
      } else {
        setResultMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResultMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex max-w-md mx-auto mt-10 newsletter-form gap-x-4">
        <input
          value={firstname} 
          onChange={(e) => setFirstname(e.target.value)}
          id="firstname"
          name="firstname"
          type="text"
          autoComplete="firstname"
          required
          className="popup min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 input"
          placeholder="Vorname"
        />
        <input
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          id="email-address"
          name="email"
          type="email"
          autoComplete="email"
          required
          className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 input"
          placeholder="E-Mail"
        />
        <button
          type="submit"
          className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white button"
        >
          Eintragen
        </button>
      </form>
      <div id="resultMessage">{resultMessage}</div>
      <br />
      <span className="home-text09">
        <input 
          type="checkbox" 
          className="home-checkbox" 
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        Ich möchte den Fakten Frieden Freiheit Newsletter abonnieren und
        akzeptiere die <a className="link" href="/datenschutz">Datenschutzbedingungen*</a>
      </span>
    </div>
  );
};

export default NewsletterForm;