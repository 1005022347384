import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import NewsletterForm from '../components/newsletter-form';

const Backdrop = styled.div`
  position: fixed;
  z-index: 1111111111;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 2s ease-in-out;
  pointer-events: ${props => props.show ? 'auto' : 'none'};
`;

const LightboxContainer = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  color: #fff;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  float: right;
  position: relative;
  bottom: 2em;
  right: -3em;
  width: 2em;
  height: 2em;
  border: 1px solid;
  border-radius: 50%;
`;

const checkAndSetLightboxVisibility = (setShowLightbox) => {
  const hasSeenLightbox = localStorage.getItem('hasSeenLightbox');

  if (!hasSeenLightbox) {
    const timeoutId = setTimeout(() => {
      setShowLightbox(true);
      localStorage.setItem('hasSeenLightbox', 'true');
    }, 10000);

    return () => clearTimeout(timeoutId);
  }
};

const showPopupOnMouseOver = (event, setShowLightbox) => {
  const { clientY } = event;
  
  // Überprüfe, ob die Maus in der oberen Fensterleiste ist
  if (clientY < 1) {
    setShowLightbox(true);
  }
};

const PopUpComponent = () => {
  const [showLightbox, setShowLightbox] = useState(false);
  const lightboxRef = useRef(null);

  useEffect(() => {
    const cleanup = checkAndSetLightboxVisibility(setShowLightbox);
    return cleanup;
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      showPopupOnMouseOver(event, setShowLightbox);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const closeLightbox = useCallback(() => {
    setShowLightbox(false);
  }, []);

  const handleOutsideClick = useCallback(
    (event) => {
      if (lightboxRef.current && !lightboxRef.current.contains(event.target)) {
        closeLightbox();
      }
    },
    [closeLightbox]
  );

  useEffect(() => {
    if (showLightbox) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showLightbox, handleOutsideClick]);

  return (
    <Backdrop show={showLightbox} id="popup2">
      <LightboxContainer id="lightbox" ref={lightboxRef}>
        <div id="content">
          <div className="popup">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 reveal-modal">
              <div className="fixed px-6 py-16 overflow-hidden bg-gray-900 shadow-2xl isolate sm:rounded-3xl sm:px-24 xl:py-32">
                <CloseButton onClick={closeLightbox}>X</CloseButton>
                <p className="max-w-xl mx-auto mt-2 text-lg leading-8 text-center text-gray-300">
                  Trage dich jetzt ein in den
                </p>
                <h2 className="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-center text-white sm:text-4xl">
                  UNZENSIERTEN NEWSLETTER
                </h2>
                <NewsletterForm />
              </div>
            </div>
          </div>
        </div>
      </LightboxContainer>
    </Backdrop>
  );
};

export default PopUpComponent;