import React from 'react';

class StarfallSection extends React.Component {
  render() {
    return (
      <section id="starfall">
        <em></em>
        <em></em>
        <em></em>
      </section>
    );
  }
}

export default StarfallSection;