import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './datenschutz.css'

const Datenschutz = (props) => {
  return (
    <div className="datenschutz-container">
      <Helmet>
        <title>Datenschutz - FriedenFaktenFreiheit</title>
        <meta
          property="og:title"
          content="Datenschutz - FriedenFaktenFreiheit"
        />
      </Helmet>
      <div className="datenschutz-hero">
        <div className="datenschutz-fixed-header">
          <Header rootClassName="header-root-class-name"></Header>
        </div>
      </div>
      <div className="datenschutz-container1">
        <div className="datenschutz-story">
          <h1 className="datenschutz-text Text2XL">Datenschutzerklärung</h1>
          <div className="datenschutz-container2">
            <div className="datenschutz-container3">
              <React.Fragment>
                <React.Fragment>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n    h2,h3, h4, p, li {\n    display: block;\n    width: 100%;\n    color: #888;\n    \n    }\n    h2, h3, h4 {\n    font-weight: 700;\n    margin: 1em 0 0;\n    }\n',
                    }}
                  />
                  <h2>1. Datenschutz auf einen Blick</h2>
                  <h3>Allgemeine Hinweise</h3>
                  <p>
                    Die folgenden Hinweise geben einen einfachen Überblick
                    darüber, was mit Ihren personenbezogenen Daten passiert,
                    wenn Sie diese Website besuchen. Personenbezogene Daten sind
                    alle Daten, mit denen Sie persönlich identifiziert werden
                    können. Ausführliche Informationen zum Thema Datenschutz
                    entnehmen Sie unserer unter diesem Text aufgeführten
                    Datenschutzerklärung.
                  </p>
                  <h3>Datenerfassung auf dieser Website</h3>
                  <h4>
                    Wer ist verantwortlich für die Datenerfassung auf dieser
                    Website?
                  </h4>
                  <p>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den
                    Websitebetreiber. Dessen Kontaktdaten können Sie dem
                    Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser
                    Datenschutzerklärung entnehmen.
                  </p>
                  <h4>Wie erfassen wir Ihre Daten?</h4>
                  <p>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                    diese mitteilen. Hierbei kann es sich z. B. um Daten
                    handeln, die Sie in ein Kontaktformular eingeben.
                  </p>
                  <p>
                    Andere Daten werden automatisch oder nach Ihrer Einwilligung
                    beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                    sind vor allem technische Daten (z. B. Internetbrowser,
                    Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                    Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
                    Website betreten.
                  </p>
                  <h4>Wofür nutzen wir Ihre Daten?</h4>
                  <p>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie
                    Bereitstellung der Website zu gewährleisten. Andere Daten
                    können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                  </p>
                  <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                  <p>
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                    Herkunft, Empfänger und Zweck Ihrer gespeicherten
                    personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                    Recht, die Berichtigung oder Löschung dieser Daten zu
                    verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
                    erteilt haben, können Sie diese Einwilligung jederzeit für
                    die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                    bestimmten Umständen die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
                    steht Ihnen ein Beschwerderecht bei der zuständigen
                    Aufsichtsbehörde zu.
                  </p>
                  <p>
                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
                    Sie sich jederzeit an uns wenden.
                  </p>
                  <h2>2. Hosting</h2>
                  <p>
                    Wir hosten die Inhalte unserer Website bei folgendem
                    Anbieter:
                  </p>
                  <h3>Hetzner</h3>
                  <p>
                    Anbieter ist die Hetzner Online GmbH, Industriestr. 25,
                    91710 Gunzenhausen (nachfolgend Hetzner).
                  </p>
                  <p>
                    Details entnehmen Sie der Datenschutzerklärung von Hetzner:
                    <a
                      href="https://www.hetzner.com/de/rechtliches/datenschutz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.hetzner.com/de/rechtliches/datenschutz
                    </a>
                    .
                  </p>
                  <p>
                    Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6
                    Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an
                    einer möglichst zuverlässigen Darstellung unserer Website.
                    Sofern eine entsprechende Einwilligung abgefragt wurde,
                    erfolgt die Verarbeitung ausschließlich auf Grundlage von
                    Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die
                    Einwilligung die Speicherung von Cookies oder den Zugriff
                    auf Informationen im Endgerät des Nutzers (z. B.
                    Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                    Einwilligung ist jederzeit widerrufbar.
                  </p>
                  <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
                  <h3>Datenschutz</h3>
                  <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                    persönlichen Daten sehr ernst. Wir behandeln Ihre
                    personenbezogenen Daten vertraulich und entsprechend den
                    gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerklärung.
                  </p>
                  <p>
                    Wenn Sie diese Website benutzen, werden verschiedene
                    personenbezogene Daten erhoben. Personenbezogene Daten sind
                    Daten, mit denen Sie persönlich identifiziert werden können.
                    Die vorliegende Datenschutzerklärung erläutert, welche Daten
                    wir erheben und wofür wir sie nutzen. Sie erläutert auch,
                    wie und zu welchem Zweck das geschieht.
                  </p>
                  <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet
                    (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
                    aufweisen kann. Ein lückenloser Schutz der Daten vor dem
                    Zugriff durch Dritte ist nicht möglich.
                  </p>
                  <h3>Hinweis zur verantwortlichen Stelle</h3>
                  <p>
                    Die verantwortliche Stelle für die Datenverarbeitung auf
                    dieser Website ist:
                  </p>
                  <p>
                    Sebastian Verboket
                    <br />
                    120 High Road, East Finchley
                    <br />
                    London
                    <br />
                    N2 9ED
                    <br />
                    United Kingdom
                  </p>
                  <p>
                    Telefon: [Telefonnummer der verantwortlichen Stelle]
                    <br />
                    E-Mail: faktenfriedenfreiheit@spost.eu
                  </p>
                  <p>
                    Verantwortliche Stelle ist die natürliche oder juristische
                    Person, die allein oder gemeinsam mit anderen über die
                    Zwecke und Mittel der Verarbeitung von personenbezogenen
                    Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                  </p>
                  <h3>Speicherdauer</h3>
                  <br />
                  <p>
                    Soweit innerhalb dieser Datenschutzerklärung keine
                    speziellere Speicherdauer genannt wurde, verbleiben Ihre
                    personenbezogenen Daten bei uns, bis der Zweck für die
                    Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
                    Löschersuchen geltend machen oder eine Einwilligung zur
                    Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
                    sofern wir keine anderen rechtlich zulässigen Gründe für die
                    Speicherung Ihrer personenbezogenen Daten haben (z. B.
                    steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                    letztgenannten Fall erfolgt die Löschung nach Fortfall
                    dieser Gründe.
                  </p>
                  <h3>
                    Allgemeine Hinweise zu den Rechtsgrundlagen der
                    Datenverarbeitung auf dieser Website
                  </h3>
                  <br />
                  <p>
                    Sofern Sie in die Datenverarbeitung eingewilligt haben,
                    verarbeiten wir Ihre personenbezogenen Daten auf Grundlage
                    von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a
                    DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1
                    DSGVO verarbeitet werden. Im Falle einer ausdrücklichen
                    Einwilligung in die Übertragung personenbezogener Daten in
                    Drittstaaten erfolgt die Datenverarbeitung außerdem auf
                    Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die
                    Speicherung von Cookies oder in den Zugriff auf
                    Informationen in Ihr Endgerät (z. B. via
                    Device-Fingerprinting) eingewilligt haben, erfolgt die
                    Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1
                    TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre
                    Daten zur Vertragserfüllung oder zur Durchführung
                    vorvertraglicher Maßnahmen erforderlich, verarbeiten wir
                    Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
                    Weiteren verarbeiten wir Ihre Daten, sofern diese zur
                    Erfüllung einer rechtlichen Verpflichtung erforderlich sind
                    auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
                    Datenverarbeitung kann ferner auf Grundlage unseres
                    berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO
                    erfolgen. Über die jeweils im Einzelfall einschlägigen
                    Rechtsgrundlagen wird in den folgenden Absätzen dieser
                    Datenschutzerklärung informiert.
                  </p>
                  <h3>
                    Hinweis zur Datenweitergabe in datenschutzrechtlich nicht
                    sichere Drittstaaten sowie die Weitergabe an US-Unternehmen,
                    die nicht DPF-zertifiziert sind
                  </h3>
                  <br />
                  <p>
                    Wir verwenden unter anderem Tools von Unternehmen mit Sitz
                    in datenschutzrechtlich nicht sicheren Drittstaaten sowie
                    US-Tools, deren Anbieter nicht nach dem EU-US-Data Privacy
                    Framework (DPF) zertifiziert sind. Wenn diese Tools aktiv
                    sind, können Ihre personenbezogene Daten in diese Staaten
                    übertragen und dort verarbeitet werden. Wir weisen darauf
                    hin, dass in datenschutzrechtlich unsicheren Drittstaaten
                    kein mit der EU vergleichbares Datenschutzniveau garantiert
                    werden kann.
                  </p>{' '}
                  <p>
                    Wir weisen darauf hin, dass die USA als sicherer Drittstaat
                    grundsätzlich ein mit der EU vergleichbares
                    Datenschutzniveau aufweisen. Eine Datenübertragung in die
                    USA ist danach zulässig, wenn der Empfänger eine
                    Zertifizierung unter dem „EU-US Data Privacy Framework“
                    (DPF) besitzt oder über geeignete zusätzliche Garantien
                    verfügt. Informationen zu Übermittlungen an Drittstaaten
                    einschließlich der Datenempfänger finden Sie in dieser
                    Datenschutzerklärung.
                  </p>
                  <h3>Empfänger von personenbezogenen Daten</h3>
                  <br />
                  <p>
                    Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit
                    verschiedenen externen Stellen zusammen. Dabei ist teilweise
                    auch eine Übermittlung von personenbezogenen Daten an diese
                    externen Stellen erforderlich. Wir geben personenbezogene
                    Daten nur dann an externe Stellen weiter, wenn dies im
                    Rahmen einer Vertragserfüllung erforderlich ist, wenn wir
                    gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von
                    Daten an Steuerbehörden), wenn wir ein berechtigtes
                    Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe
                    haben oder wenn eine sonstige Rechtsgrundlage die
                    Datenweitergabe erlaubt. Beim Einsatz von
                    Auftragsverarbeitern geben wir personenbezogene Daten
                    unserer Kunden nur auf Grundlage eines gültigen Vertrags
                    über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
                    Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung
                    geschlossen.
                  </p>
                  <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                  <br />
                  <p>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                    ausdrücklichen Einwilligung möglich. Sie können eine bereits
                    erteilte Einwilligung jederzeit widerrufen. Die
                    Rechtmäßigkeit der bis zum Widerruf erfolgten
                    Datenverarbeitung bleibt vom Widerruf unberührt.
                  </p>
                  <h3>
                    Widerspruchsrecht gegen die Datenerhebung in besonderen
                    Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
                  </h3>
                  <br />
                  <p>
                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1
                    LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT,
                    AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
                    ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
                    DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
                    DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
                    RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                    ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
                    WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
                    PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN,
                    WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                    VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                    FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                    GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                    RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                  </p>{' '}
                  <p>
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                    DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
                    JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                    BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
                    WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
                    ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                    WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                    ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
                    VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
                  </p>
                  <h3>
                    Beschwerde­recht bei der zuständigen Aufsichts­behörde
                  </h3>
                  <br />
                  <p>
                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
                    ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere
                    in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                    Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
                    Das Beschwerderecht besteht unbeschadet anderweitiger
                    verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                  </p>
                  <h3>Recht auf Daten­übertrag­barkeit</h3>
                  <br />
                  <p>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                    Einwilligung oder in Erfüllung eines Vertrags automatisiert
                    verarbeiten, an sich oder an einen Dritten in einem
                    gängigen, maschinenlesbaren Format aushändigen zu lassen.
                    Sofern Sie die direkte Übertragung der Daten an einen
                    anderen Verantwortlichen verlangen, erfolgt dies nur, soweit
                    es technisch machbar ist.
                  </p>
                  <h3>Auskunft, Berichtigung und Löschung</h3>
                  <br />
                  <p>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                    jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                    gespeicherten personenbezogenen Daten, deren Herkunft und
                    Empfänger und den Zweck der Datenverarbeitung und ggf. ein
                    Recht auf Berichtigung oder Löschung dieser Daten. Hierzu
                    sowie zu weiteren Fragen zum Thema personenbezogene Daten
                    können Sie sich jederzeit an uns wenden.
                  </p>
                  <h3>Recht auf Einschränkung der Verarbeitung</h3>
                  <br />
                  <p>
                    Sie haben das Recht, die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen. Hierzu können
                    Sie sich jederzeit an uns wenden. Das Recht auf
                    Einschränkung der Verarbeitung besteht in folgenden Fällen:
                  </p>
                  <ul>
                    {' '}
                    <li>
                      Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                      personenbezogenen Daten bestreiten, benötigen wir in der
                      Regel Zeit, um dies zu überprüfen. Für die Dauer der
                      Prüfung haben Sie das Recht, die Einschränkung der
                      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                    </li>{' '}
                    <li>
                      Wenn die Verarbeitung Ihrer personenbezogenen Daten
                      unrechtmäßig geschah/geschieht, können Sie statt der
                      Löschung die Einschränkung der Datenverarbeitung
                      verlangen.
                    </li>{' '}
                    <li>
                      Wenn wir Ihre personenbezogenen Daten nicht mehr
                      benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
                      Geltendmachung von Rechtsansprüchen benötigen, haben Sie
                      das Recht, statt der Löschung die Einschränkung der
                      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                    </li>{' '}
                    <li>
                      Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                      eingelegt haben, muss eine Abwägung zwischen Ihren und
                      unseren Interessen vorgenommen werden. Solange noch nicht
                      feststeht, wessen Interessen überwiegen, haben Sie das
                      Recht, die Einschränkung der Verarbeitung Ihrer
                      personenbezogenen Daten zu verlangen.
                    </li>{' '}
                  </ul>{' '}
                  <p>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                    eingeschränkt haben, dürfen diese Daten – von ihrer
                    Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
                    Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                    natürlichen oder juristischen Person oder aus Gründen eines
                    wichtigen öffentlichen Interesses der Europäischen Union
                    oder eines Mitgliedstaats verarbeitet werden.
                  </p>
                  <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                  <br />
                  <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel
                    Bestellungen oder Anfragen, die Sie an uns als
                    Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
                    Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                    Adresszeile des Browsers von „http://“ auf „https://“
                    wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                  </p>{' '}
                  <p>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
                    die Daten, die Sie an uns übermitteln, nicht von Dritten
                    mitgelesen werden.
                  </p>
                  <h3>Widerspruch gegen Werbe-E-Mails</h3>
                  <br />
                  <p>
                    Der Nutzung von im Rahmen der Impressumspflicht
                    veröffentlichten Kontaktdaten zur Übersendung von nicht
                    ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit widersprochen. Die
                    Betreiber der Seiten behalten sich ausdrücklich rechtliche
                    Schritte im Falle der unverlangten Zusendung von
                    Werbeinformationen, etwa durch Spam-E-Mails, vor.
                  </p>
                  <h2>4. Datenerfassung auf dieser Website</h2>
                  <h3>Cookies</h3>
                  <br />
                  <p>
                    Unsere Internetseiten verwenden so genannte „Cookies“.
                    Cookies sind kleine Datenpakete und richten auf Ihrem
                    Endgerät keinen Schaden an. Sie werden entweder
                    vorübergehend für die Dauer einer Sitzung (Session-Cookies)
                    oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
                    gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
                    automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
                    Endgerät gespeichert, bis Sie diese selbst löschen oder eine
                    automatische Löschung durch Ihren Webbrowser erfolgt.
                  </p>{' '}
                  <p>
                    Cookies können von uns (First-Party-Cookies) oder von
                    Drittunternehmen stammen (sog. Third-Party-Cookies).
                    Third-Party-Cookies ermöglichen die Einbindung bestimmter
                    Dienstleistungen von Drittunternehmen innerhalb von
                    Webseiten (z. B. Cookies zur Abwicklung von
                    Zahlungsdienstleistungen).
                  </p>{' '}
                  <p>
                    Cookies haben verschiedene Funktionen. Zahlreiche Cookies
                    sind technisch notwendig, da bestimmte Webseitenfunktionen
                    ohne diese nicht funktionieren würden (z. B. die
                    Warenkorbfunktion oder die Anzeige von Videos). Andere
                    Cookies können zur Auswertung des Nutzerverhaltens oder zu
                    Werbezwecken verwendet werden.
                  </p>{' '}
                  <p>
                    Cookies, die zur Durchführung des elektronischen
                    Kommunikationsvorgangs, zur Bereitstellung bestimmter, von
                    Ihnen erwünschter Funktionen (z. B. für die
                    Warenkorbfunktion) oder zur Optimierung der Website (z. B.
                    Cookies zur Messung des Webpublikums) erforderlich sind
                    (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1
                    lit. f DSGVO gespeichert, sofern keine andere
                    Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der Speicherung von notwendigen
                    Cookies zur technisch fehlerfreien und optimierten
                    Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
                    Speicherung von Cookies und vergleichbaren
                    Wiedererkennungstechnologien abgefragt wurde, erfolgt die
                    Verarbeitung ausschließlich auf Grundlage dieser
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
                    TTDSG); die Einwilligung ist jederzeit widerrufbar.
                  </p>{' '}
                  <p>
                    Sie können Ihren Browser so einstellen, dass Sie über das
                    Setzen von Cookies informiert werden und Cookies nur im
                    Einzelfall erlauben, die Annahme von Cookies für bestimmte
                    Fälle oder generell ausschließen sowie das automatische
                    Löschen der Cookies beim Schließen des Browsers aktivieren.
                    Bei der Deaktivierung von Cookies kann die Funktionalität
                    dieser Website eingeschränkt sein.
                  </p>{' '}
                  <p>
                    Welche Cookies und Dienste auf dieser Website eingesetzt
                    werden, können Sie dieser Datenschutzerklärung entnehmen.
                  </p>
                  <h3>Kontaktformular</h3>
                  <br />
                  <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                    werden Ihre Angaben aus dem Anfrageformular inklusive der
                    von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                    der Anfrage und für den Fall von Anschlussfragen bei uns
                    gespeichert. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter.
                  </p>{' '}
                  <p>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art.
                    6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
                    eines Vertrags zusammenhängt oder zur Durchführung
                    vorvertraglicher Maßnahmen erforderlich ist. In allen
                    übrigen Fällen beruht die Verarbeitung auf unserem
                    berechtigten Interesse an der effektiven Bearbeitung der an
                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
                    auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
                    diese abgefragt wurde; die Einwilligung ist jederzeit
                    widerrufbar.
                  </p>{' '}
                  <p>
                    Die von Ihnen im Kontaktformular eingegebenen Daten
                    verbleiben bei uns, bis Sie uns zur Löschung auffordern,
                    Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                    für die Datenspeicherung entfällt (z. B. nach
                    abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
                    gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
                    – bleiben unberührt.
                  </p>
                  <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
                  <br />
                  <p>
                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren,
                    wird Ihre Anfrage inklusive aller daraus hervorgehenden
                    personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                    Bearbeitung Ihres Anliegens bei uns gespeichert und
                    verarbeitet. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter.
                  </p>{' '}
                  <p>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art.
                    6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
                    eines Vertrags zusammenhängt oder zur Durchführung
                    vorvertraglicher Maßnahmen erforderlich ist. In allen
                    übrigen Fällen beruht die Verarbeitung auf unserem
                    berechtigten Interesse an der effektiven Bearbeitung der an
                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
                    auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
                    diese abgefragt wurde; die Einwilligung ist jederzeit
                    widerrufbar.
                  </p>{' '}
                  <p>
                    Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                    verbleiben bei uns, bis Sie uns zur Löschung auffordern,
                    Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                    für die Datenspeicherung entfällt (z. B. nach
                    abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                    gesetzliche Bestimmungen – insbesondere gesetzliche
                    Aufbewahrungsfristen – bleiben unberührt.
                  </p>
                  <h3>Kommentar­funktion auf dieser Website</h3>
                  <br />
                  <p>
                    Für die Kommentarfunktion auf dieser Seite werden neben
                    Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung
                    des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht
                    anonym posten, der von Ihnen gewählte Nutzername
                    gespeichert.
                  </p>
                  <h4>Speicherdauer der Kommentare</h4>{' '}
                  <p>
                    Die Kommentare und die damit verbundenen Daten werden
                    gespeichert und verbleiben auf dieser Website, bis der
                    kommentierte Inhalt vollständig gelöscht wurde oder die
                    Kommentare aus rechtlichen Gründen gelöscht werden müssen
                    (z. B. beleidigende Kommentare).
                  </p>
                  <h4>Rechtsgrundlage</h4>{' '}
                  <p>
                    Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine
                    von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu
                    reicht eine formlose Mitteilung per E-Mail an uns. Die
                    Rechtmäßigkeit der bereits erfolgten
                    Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                  </p>
                  <h2>5. Soziale Medien</h2>
                  <h3>Facebook</h3>
                  <br />
                  <p>
                    Auf dieser Website sind Elemente des sozialen Netzwerks
                    Facebook integriert. Anbieter dieses Dienstes ist die Meta
                    Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2,
                    Irland. Die erfassten Daten werden nach Aussage von Facebook
                    jedoch auch in die USA und in andere Drittländer übertragen.
                  </p>{' '}
                  <p>
                    Eine Übersicht über die Facebook Social-Media-Elemente
                    finden Sie hier:{' '}
                    <a
                      href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://developers.facebook.com/docs/plugins/?locale=de_DE
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Wenn das Social-Media-Element aktiv ist, wird eine direkte
                    Verbindung zwischen Ihrem Endgerät und dem Facebook-Server
                    hergestellt. Facebook erhält dadurch die Information, dass
                    Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn
                    Sie den Facebook „Like-Button“ anklicken, während Sie in
                    Ihrem Facebook-Account eingeloggt sind, können Sie die
                    Inhalte dieser Website auf Ihrem Facebook-Profil verlinken.
                    Dadurch kann Facebook den Besuch dieser Website Ihrem
                    Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
                    Anbieter der Seiten keine Kenntnis vom Inhalt der
                    übermittelten Daten sowie deren Nutzung durch Facebook
                    erhalten. Weitere Informationen hierzu finden Sie in der
                    Datenschutzerklärung von Facebook unter:{' '}
                    <a
                      href="https://de-de.facebook.com/privacy/explanation"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://de-de.facebook.com/privacy/explanation
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt
                    der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs.
                    1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist
                    jederzeit widerrufbar. Soweit keine Einwilligung eingeholt
                    wurde, erfolgt die Verwendung des Dienstes auf Grundlage
                    unseres berechtigten Interesses an einer möglichst
                    umfassenden Sichtbarkeit in den Sozialen Medien.
                  </p>{' '}
                  <p>
                    Soweit mit Hilfe des hier beschriebenen Tools
                    personenbezogene Daten auf unserer Website erfasst und an
                    Facebook weitergeleitet werden, sind wir und die Meta
                    Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal
                    Harbour, Dublin 2, Irland gemeinsam für diese
                    Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die
                    gemeinsame Verantwortlichkeit beschränkt sich dabei
                    ausschließlich auf die Erfassung der Daten und deren
                    Weitergabe an Facebook. Die nach der Weiterleitung
                    erfolgende Verarbeitung durch Facebook ist nicht Teil der
                    gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
                    Verpflichtungen wurden in einer Vereinbarung über gemeinsame
                    Verarbeitung festgehalten. Den Wortlaut der Vereinbarung
                    finden Sie unter:{' '}
                    <a
                      href="https://www.facebook.com/legal/controller_addendum"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/legal/controller_addendum
                    </a>
                    . Laut dieser Vereinbarung sind wir für die Erteilung der
                    Datenschutzinformationen beim Einsatz des Facebook-Tools und
                    für die datenschutzrechtlich sichere Implementierung des
                    Tools auf unserer Website verantwortlich. Für die
                    Datensicherheit der Facebook-Produkte ist Facebook
                    verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen)
                    hinsichtlich der bei Facebook verarbeiteten Daten können Sie
                    direkt bei Facebook geltend machen. Wenn Sie die
                    Betroffenenrechte bei uns geltend machen, sind wir
                    verpflichtet, diese an Facebook weiterzuleiten.
                  </p>{' '}
                  <p>
                    Die Datenübertragung in die USA wird auf die
                    Standardvertragsklauseln der EU-Kommission gestützt. Details
                    finden Sie hier:{' '}
                    <a
                      href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/legal/EU_data_transfer_addendum
                    </a>
                    ,{' '}
                    <a
                      href="https://de-de.facebook.com/help/566994660333381"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://de-de.facebook.com/help/566994660333381
                    </a>{' '}
                    und{' '}
                    <a
                      href="https://www.facebook.com/policy.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/policy.php
                    </a>
                    .
                  </p>
                  <p>
                    Das Unternehmen verfügt über eine Zertifizierung nach dem
                    „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein
                    Übereinkommen zwischen der Europäischen Union und den USA,
                    der die Einhaltung europäischer Datenschutzstandards bei
                    Datenverarbeitungen in den USA gewährleisten soll. Jedes
                    nach dem DPF zertifizierte Unternehmen verpflichtet sich,
                    diese Datenschutzstandards einzuhalten. Weitere
                    Informationen hierzu erhalten Sie vom Anbieter unter
                    folgendem Link:{' '}
                    <a
                      href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
                    </a>
                  </p>
                  <h3>Twitter</h3>
                  <br />
                  <p>
                    Auf dieser Website sind Funktionen des Dienstes Twitter
                    eingebunden. Diese Funktionen werden angeboten durch die
                    Twitter International Company, One Cumberland Place, Fenian
                    Street, Dublin 2, D02 AX07, Irland.
                  </p>{' '}
                  <p>
                    Wenn das Social-Media-Element aktiv ist, wird eine direkte
                    Verbindung zwischen Ihrem Endgerät und dem Twitter-Server
                    hergestellt. Twitter erhält dadurch Informationen über den
                    Besuch dieser Website durch Sie. Durch das Benutzen von
                    Twitter und der Funktion „Re-Tweet“ werden die von Ihnen
                    besuchten Websites mit Ihrem Twitter-Account verknüpft und
                    anderen Nutzern bekannt gegeben. Wir weisen darauf hin, dass
                    wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                    übermittelten Daten sowie deren Nutzung durch Twitter
                    erhalten. Weitere Informationen hierzu finden Sie in der
                    Datenschutzerklärung von Twitter unter:{' '}
                    <a
                      href="https://twitter.com/de/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/de/privacy
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt
                    der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs.
                    1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist
                    jederzeit widerrufbar. Soweit keine Einwilligung eingeholt
                    wurde, erfolgt die Verwendung des Dienstes auf Grundlage
                    unseres berechtigten Interesses an einer möglichst
                    umfassenden Sichtbarkeit in den Sozialen Medien.
                  </p>{' '}
                  <p>
                    Die Datenübertragung in die USA wird auf die
                    Standardvertragsklauseln der EU-Kommission gestützt. Details
                    finden Sie hier:{' '}
                    <a
                      href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://gdpr.twitter.com/en/controller-to-controller-transfers.html
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Ihre Datenschutzeinstellungen bei Twitter können Sie in den
                    Konto-Einstellungen unter{' '}
                    <a
                      href="https://twitter.com/account/settings"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/account/settings
                    </a>{' '}
                    ändern.
                  </p>
                  <h3>Instagram</h3>
                  <br />
                  <p>
                    Auf dieser Website sind Funktionen des Dienstes Instagram
                    eingebunden. Diese Funktionen werden angeboten durch die
                    Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand
                    Canal Harbour, Dublin 2, Irland.
                  </p>{' '}
                  <p>
                    Wenn das Social-Media-Element aktiv ist, wird eine direkte
                    Verbindung zwischen Ihrem Endgerät und dem Instagram-Server
                    hergestellt. Instagram erhält dadurch Informationen über den
                    Besuch dieser Website durch Sie.
                  </p>{' '}
                  <p>
                    Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können
                    Sie durch Anklicken des Instagram-Buttons die Inhalte dieser
                    Website mit Ihrem Instagram-Profil verlinken. Dadurch kann
                    Instagram den Besuch dieser Website Ihrem Benutzerkonto
                    zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
                    Seiten keine Kenntnis vom Inhalt der übermittelten Daten
                    sowie deren Nutzung durch Instagram erhalten.
                  </p>{' '}
                  <p>
                    Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt
                    der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs.
                    1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist
                    jederzeit widerrufbar. Soweit keine Einwilligung eingeholt
                    wurde, erfolgt die Verwendung des Dienstes auf Grundlage
                    unseres berechtigten Interesses an einer möglichst
                    umfassenden Sichtbarkeit in den Sozialen Medien.
                  </p>{' '}
                  <p>
                    Soweit mit Hilfe des hier beschriebenen Tools
                    personenbezogene Daten auf unserer Website erfasst und an
                    Facebook bzw. Instagram weitergeleitet werden, sind wir und
                    die Meta Platforms Ireland Limited, 4 Grand Canal Square,
                    Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
                    Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die
                    gemeinsame Verantwortlichkeit beschränkt sich dabei
                    ausschließlich auf die Erfassung der Daten und deren
                    Weitergabe an Facebook bzw. Instagram. Die nach der
                    Weiterleitung erfolgende Verarbeitung durch Facebook bzw.
                    Instagram ist nicht Teil der gemeinsamen Verantwortung. Die
                    uns gemeinsam obliegenden Verpflichtungen wurden in einer
                    Vereinbarung über gemeinsame Verarbeitung festgehalten. Den
                    Wortlaut der Vereinbarung finden Sie unter:{' '}
                    <a
                      href="https://www.facebook.com/legal/controller_addendum"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/legal/controller_addendum
                    </a>
                    . Laut dieser Vereinbarung sind wir für die Erteilung der
                    Datenschutzinformationen beim Einsatz des Facebook- bzw.
                    Instagram-Tools und für die datenschutzrechtlich sichere
                    Implementierung des Tools auf unserer Website
                    verantwortlich. Für die Datensicherheit der Facebook bzw.
                    Instagram-Produkte ist Facebook verantwortlich.
                    Betroffenenrechte (z. B. Auskunftsersuchen) hinsichtlich der
                    bei Facebook bzw. Instagram verarbeiteten Daten können Sie
                    direkt bei Facebook geltend machen. Wenn Sie die
                    Betroffenenrechte bei uns geltend machen, sind wir
                    verpflichtet, diese an Facebook weiterzuleiten.
                  </p>{' '}
                  <p>
                    Die Datenübertragung in die USA wird auf die
                    Standardvertragsklauseln der EU-Kommission gestützt. Details
                    finden Sie hier:{' '}
                    <a
                      href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/legal/EU_data_transfer_addendum
                    </a>
                    ,{' '}
                    <a
                      href="https://privacycenter.instagram.com/policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://privacycenter.instagram.com/policy/
                    </a>{' '}
                    und{' '}
                    <a
                      href="https://de-de.facebook.com/help/566994660333381"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://de-de.facebook.com/help/566994660333381
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Weitere Informationen hierzu finden Sie in der
                    Datenschutzerklärung von Instagram:{' '}
                    <a
                      href="https://privacycenter.instagram.com/policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://privacycenter.instagram.com/policy/
                    </a>
                    .
                  </p>
                  <p>
                    Das Unternehmen verfügt über eine Zertifizierung nach dem
                    „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein
                    Übereinkommen zwischen der Europäischen Union und den USA,
                    der die Einhaltung europäischer Datenschutzstandards bei
                    Datenverarbeitungen in den USA gewährleisten soll. Jedes
                    nach dem DPF zertifizierte Unternehmen verpflichtet sich,
                    diese Datenschutzstandards einzuhalten. Weitere
                    Informationen hierzu erhalten Sie vom Anbieter unter
                    folgendem Link:{' '}
                    <a
                      href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
                    </a>
                  </p>
                  <h2>6. Newsletter</h2>
                  <h3>Newsletter­daten</h3>
                  <br />
                  <p>
                    Wenn Sie den auf der Website angebotenen Newsletter beziehen
                    möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
                    Informationen, welche uns die Überprüfung gestatten, dass
                    Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit
                    dem Empfang des Newsletters einverstanden sind. Weitere
                    Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
                    Diese Daten verwenden wir ausschließlich für den Versand der
                    angeforderten Informationen und geben diese nicht an Dritte
                    weiter.
                  </p>{' '}
                  <p>
                    Die Verarbeitung der in das Newsletteranmeldeformular
                    eingegebenen Daten erfolgt ausschließlich auf Grundlage
                    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die
                    erteilte Einwilligung zur Speicherung der Daten, der
                    E-Mail-Adresse sowie deren Nutzung zum Versand des
                    Newsletters können Sie jederzeit widerrufen, etwa über den
                    „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der
                    bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
                    Widerruf unberührt.
                  </p>{' '}
                  <p>
                    Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
                    hinterlegten Daten werden von uns bis zu Ihrer Austragung
                    aus dem Newsletter bei uns bzw. dem
                    Newsletterdiensteanbieter gespeichert und nach der
                    Abbestellung des Newsletters oder nach Zweckfortfall aus der
                    Newsletterverteilerliste gelöscht. Wir behalten uns vor,
                    E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
                    Ermessen im Rahmen unseres berechtigten Interesses nach Art.
                    6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
                  </p>{' '}
                  <p>
                    Daten, die zu anderen Zwecken bei uns gespeichert wurden,
                    bleiben hiervon unberührt.
                  </p>{' '}
                  <p>
                    Nach Ihrer Austragung aus der Newsletterverteilerliste wird
                    Ihre E-Mail-Adresse bei uns bzw. dem
                    Newsletterdiensteanbieter ggf. in einer Blacklist
                    gespeichert, sofern dies zur Verhinderung künftiger Mailings
                    erforderlich ist. Die Daten aus der Blacklist werden nur für
                    diesen Zweck verwendet und nicht mit anderen Daten
                    zusammengeführt. Dies dient sowohl Ihrem Interesse als auch
                    unserem Interesse an der Einhaltung der gesetzlichen
                    Vorgaben beim Versand von Newslettern (berechtigtes
                    Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
                    Speicherung in der Blacklist ist zeitlich nicht befristet.{' '}
                    <strong>
                      Sie können der Speicherung widersprechen, sofern Ihre
                      Interessen unser berechtigtes Interesse überwiegen.
                    </strong>
                  </p>
                  <h2>7. Plugins und Tools</h2>
                  <h3>YouTube</h3>
                  <br />
                  <p>
                    Diese Website bindet Videos der Website YouTube ein.
                    Betreiber der Website ist die Google Ireland Limited
                    („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
                  </p>{' '}
                  <p>
                    Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
                    eingebunden ist, wird eine Verbindung zu den Servern von
                    YouTube hergestellt. Dabei wird dem YouTube-Server
                    mitgeteilt, welche unserer Seiten Sie besucht haben.
                  </p>{' '}
                  <p>
                    Des Weiteren kann YouTube verschiedene Cookies auf Ihrem
                    Endgerät speichern oder vergleichbare Technologien zur
                    Wiedererkennung verwenden (z. B. Device-Fingerprinting). Auf
                    diese Weise kann YouTube Informationen über Besucher dieser
                    Website erhalten. Diese Informationen werden u. a.
                    verwendet, um Videostatistiken zu erfassen, die
                    Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
                    vorzubeugen.
                  </p>{' '}
                  <p>
                    Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
                    ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
                    persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                    indem Sie sich aus Ihrem YouTube-Account ausloggen.
                  </p>{' '}
                  <p>
                    Die Nutzung von YouTube erfolgt im Interesse einer
                    ansprechenden Darstellung unserer Online-Angebote. Dies
                    stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
                    lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                    abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                    Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
                    TTDSG, soweit die Einwilligung die Speicherung von Cookies
                    oder den Zugriff auf Informationen im Endgerät des Nutzers
                    (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                    Die Einwilligung ist jederzeit widerrufbar.
                  </p>{' '}
                  <p>
                    Weitere Informationen zum Umgang mit Nutzerdaten finden Sie
                    in der Datenschutzerklärung von YouTube unter:{' '}
                    <a
                      href="https://policies.google.com/privacy?hl=de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://policies.google.com/privacy?hl=de
                    </a>
                    .
                  </p>
                  <p>
                    Das Unternehmen verfügt über eine Zertifizierung nach dem
                    „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein
                    Übereinkommen zwischen der Europäischen Union und den USA,
                    der die Einhaltung europäischer Datenschutzstandards bei
                    Datenverarbeitungen in den USA gewährleisten soll. Jedes
                    nach dem DPF zertifizierte Unternehmen verpflichtet sich,
                    diese Datenschutzstandards einzuhalten. Weitere
                    Informationen hierzu erhalten Sie vom Anbieter unter
                    folgendem Link:{' '}
                    <a
                      href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
                    </a>
                  </p>
                  <h3>Spotify</h3>
                  <br />
                  <p>
                    Auf dieser Website sind Funktionen des Musik-Dienstes
                    Spotify eingebunden. Anbieter ist die Spotify AB, Birger
                    Jarlsgatan 61, 113 56 Stockholm in Schweden. Die Spotify
                    Plugins erkennen Sie an dem grünen Logo auf dieser Website.
                    Eine Übersicht über die Spotify-Plugins finden Sie unter:{' '}
                    <a
                      href="https://developer.spotify.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://developer.spotify.com
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Dadurch kann beim Besuch dieser Website über das Plugin eine
                    direkte Verbindung zwischen Ihrem Browser und dem
                    Spotify-Server hergestellt werden. Spotify erhält dadurch
                    die Information, dass Sie mit Ihrer IP-Adresse diese Website
                    besucht haben. Wenn Sie den Spotify Button anklicken,
                    während Sie in Ihrem Spotify-Account eingeloggt sind, können
                    Sie die Inhalte dieser Website auf Ihrem Spotify Profil
                    verlinken. Dadurch kann Spotify den Besuch dieser Website
                    Ihrem Benutzerkonto zuordnen.
                  </p>{' '}
                  <p>
                    Wir weisen darauf hin, dass bei der Nutzung von Spotify
                    Cookies von Google Analytics eingesetzt werden, sodass Ihre
                    Nutzungsdaten bei der Nutzung von Spotify auch an Google
                    weitergegeben werden können. Google Analytics ist ein Tool
                    des Google-Konzerns zur Analyse des Nutzerverhaltens mit
                    Sitz in den USA. Für diese Einbindung ist allein Spotify
                    verantwortlich. Wir als Websitebetreiber haben auf diese
                    Verarbeitung keinen Einfluss.
                  </p>{' '}
                  <p>
                    Die Speicherung und Analyse der Daten erfolgt auf Grundlage
                    von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der ansprechenden akustischen
                    Ausgestaltung seiner Website. Sofern eine entsprechende
                    Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                    ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
                    und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
                    Speicherung von Cookies oder den Zugriff auf Informationen
                    im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
                    Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
                    widerrufbar.
                  </p>{' '}
                  <p>
                    Weitere Informationen hierzu finden Sie in der
                    Datenschutzerklärung von Spotify:{' '}
                    <a
                      href="https://www.spotify.com/de/legal/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.spotify.com/de/legal/privacy-policy/
                    </a>
                    .
                  </p>{' '}
                  <p>
                    Wenn Sie nicht wünschen, dass Spotify den Besuch dieser
                    Website Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie
                    sich bitte aus Ihrem Spotify-Benutzerkonto aus.
                  </p>
                  <p>
                    Quelle:{' '}
                    <a href="https://www.e-recht24.de">
                      https://www.e-recht24.de
                    </a>
                  </p>
                </React.Fragment>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      <div className="datenschutz-posts"></div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default Datenschutz
