import React, { useState, useEffect } from 'react';

const ContentSlider = () => {
  const texts = [
    { id: 1, content: "<span>Man muss mit der Wahrheit beginnen. Nur mit der Wahrheit kommen wir weiter. Denn Entscheidungen, die auf Lügen oder Unwissenheit basieren, können nicht zu einem guten Ergebnis führen.</span><br></br><span className='home-text04'>– Julian Assange</span><br></br>" },
    { id: 2, content: "<span>Nonkonformität ist die einzige wahre Leidenschaft, von der es sich zu leiten lohnt.</span><br></br><span className='home-text04'>– Julian Assange</span><br></br>" },
    { id: 3, content: "<span>Eine der besten Möglichkeiten, Gerechtigkeit zu erreichen, besteht darin, Ungerechtigkeit aufzudecken.</span><br></br><span className='home-text04'>– Julian Assange</span><br></br>"  }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="content-slider">
      <div dangerouslySetInnerHTML={{ __html: texts[currentIndex].content }} />
    </div>
  );
};

export default ContentSlider;