import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './page404.css'

const Page404 = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>404 - FaktenFriedenFreiheit</title>
        <meta property="og:title" content="404 - FaktenFriedenFreiheit" />
      </Helmet>
      <div className="page-hero">
        <div className="page-bg"></div>
        <Header></Header>
        <div className="page-container1">
          <img
            alt="image"
            src="/img/fff-logo-neu-weiss-400h.png"
            className="page-image"
          />
          <div className="page-container2">
            <h1 className="page-text">
              <span>404 Error</span>
              <br></br>
              <span>...die Seite konnte nicht gefunden werden...</span>
            </h1>
          </div>
          <img alt="image" src="/gray-vector.svg" className="page-image1" />
          <img alt="image" src="/white-vector.svg" className="page-image2" />
        </div>
        <div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Page404
