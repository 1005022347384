import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import PopUpComponent from '../components/newsletter-popup'
import NewsletterForm from '../components/newsletter-form'
import StarfallSection from '../components/starfall.js'
import '../components/starfall.css'
import './home.css'
import ContentSlider from '../components/contentslider.jsx';


function stopPropagation (e) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
}

const Home = (props) => {
  return (
    
    <div className="home-container">
      <Helmet>
        <title>FriedenFaktenFreiheit</title>
        <meta property="og:title" content="FriedenFaktenFreiheit" />
        <script src="https://ta004cfab.emailsys1a.net/form/287/106/ec4babd48a/popup.js?_g=1725821518" async></script>
      </Helmet>
      <div className="home-hero">
        <div className="home-bg"></div>
        <Header></Header>
        <div id="logo-claim" className="home-container04">
            <h1 className='logoName'><div className="home-container05"></div><span>Fakten Frieden Freiheit</span></h1>
          </div>
        <div id="qbparallax" className="home-container01">
          <div className="home-container02">
          <StarfallSection></StarfallSection>
            <div className="home-container03">
              <React.Fragment>
              
                  <div className="parallax">
                    <div className="parallax__layer parallax__layer__4">
                      <img src="https://vahub.ffn.network/images/faktenfriedenfreiheit/planet.webp" />
                    </div>
                    <div className="parallax__layer parallax__layer__6">
                      <img src="https://vahub.ffn.network/images/faktenfriedenfreiheit/galaxy.webp" />
                    </div>
                  </div>
                  
                  
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      <img src="/dark-vector.svg" className="home-image" />
      <div className="home-section3">
        <span className="home-text">
        <ContentSlider />
        </span>
        <div className="home-banner">
          <span className="home-text06">
            <span>
              Trage dich jetzt ein in den
            </span>
            <br></br>
          </span>
          <h2 className="home-heading">UNZENSIERTEN NEWSLETTER</h2>
          <div className="home-container06">
          <NewsletterForm></NewsletterForm>
          </div>
        </div>
        <div className='introTxt'>
          <h2>
          Meine lieben Freunde, es ist soweit.
          </h2>
          <h3>
            Ich darf euch nun endlich meinen kostenlosen Newsletter anbieten. 
          </h3>
          <br />
          <p>
            Nachdem ich bereits auf Facebook und TikTok für eine zeitlang
            gesperrt, mein erster YouTube Kanal 2020 mit über 30.000 Abonnenten
            gelöscht wurde, habe ich mich entschlossen die heikelsten und
            brisantesten Informationen nicht mehr auf Facebook, Instagram oder
            TikTok zu veröffentlichen. Auch meine Reichweite ist seitdem extrem
            eingeschränkt. Ich will auf keinen Fall eine weitere Löschung
            riskieren - da hat am Ende niemand etwas von. 
          </p>
          <br />
          <p>
            Der Digital Services Act (DSA), der am 15.08.23 verabschiedet wurde,
            macht die ganze Sache nur noch unsicherer. 
          </p>
          <br />
          <p>
            Ich möchte euch jedoch unbedingt weiterhin
            <br />
           zensurfrei und vorallem unabhängig
          </p>
          <br />
          <p>
            von sozialen Plattformen erreichen. Nachdem ich mit einem tollen
            Team im Rücken viel Zeit und Liebe reingesteckt habe ist es endlich
            so weit, <b>mein Newsletter ist fertig für Dich</b>.</p>
            <br />
          <p>
            Das ist der sicherste Weg euch ohne Reglementierungen alle
            wichtigsten und aktuellen Informationen zukommen zu lassen. Trage
            dich direkt ein und bleibe zukünftig bestens informiert.
          </p>
          <p>
            Die Webseite wird in Zukunft noch weiter ausgebaut. Bleibt gespannt!
            Für die Freiheit. Ich danke dir für dein Vertrauen.
          </p>
          <br />
          <p>Sebbe </p>
          <p>Denkt dran - denkt selbst </p>
          <br />
          <br />
        </div>
      </div>
      <div id="videos-sebbe" className="home-section1">
        <div className="home-container08">
          <div className="home-container09">
            <h6 className="home-text38">Fakten.Frieden.Freiheit</h6>
            <h3 className="home-text39 Healine">Neueste Videos</h3>
          </div>
          <div className="home-container10">
            <div className="home-container11">
              <React.Fragment>
                  <div
                    style={{
                      height: '100%',
                      overflowX: 'hidden',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <div className="csslider infinity" id="slider1">
                      <input
                        type="radio"
                        name="slides"
                        defaultChecked={true}
                        id="slides_1"
                      />
                      <input type="radio" name="slides" id="slides_2" />
                      <input type="radio" name="slides" id="slides_3" />
                      <input type="radio" name="slides" id="slides_4" />
                      <input type="radio" name="slides" id="slides_5" />
                      <input type="radio" name="slides" id="slides_6" />
                      <input type="radio" name="slides" id="slides_7" />
                      <input type="radio" name="slides" id="slides_8" />
                      <input type="radio" name="slides" id="slides_9" />
                      <input type="radio" name="slides" id="slides_10" />
                      <ul>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/TFyr28S9mdU?si=noUiKG8H6LXLjQEU"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/A7IHpQjLEFQ?si=ulvrd8EZXmxXLX_4"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/fZZnH1pXzDY?si=waRs7XohSpetj5Bz"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/E_it5PDBA58?si=7jkRWihaUPVTGRGX"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/uLljq2GuSVI?si=kuWL40PRcMcxShGo"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/vemTR67r2nI?si=Sl44BX8nexiD0toQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/2BUcMHYFrng?si=8KibAbwxz4IX7Nxu"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/KFRCVdcoK_w?si=ZbYp83oPOyRE1JOa"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/quI4QshP130?si=9GbmqTa9e3I36oCP"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/ztqD28FgBpU?si=rCmSptvPjsTrXoUp"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/nastJYFTn6k?si=12dXHPx7SddTslTM"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/2EA00CnoEgo?si=WmEUvdI7ptnOGjNY"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/TFyr28S9mdU?si=P0KOWNC6eAZebewK"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/fxjPfGaZCJU?si=NnDTNvq_NHNfCSey"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          />
                        </li>
                        <li>
                          <p>
                            <video controls={true} preload="">
                              <source src="" />
                            </video>
                          </p>
                        </li>
                        <li>
                          <p>
                            <video controls={true} preload="">
                              <source src="" />
                            </video>
                          </p>
                        </li>
                      </ul>
                      <div className="arrows">
                        <label htmlFor="slides_1" />
                        <label htmlFor="slides_2" />
                        <label htmlFor="slides_3" />
                        <label htmlFor="slides_4" />
                        <label htmlFor="slides_5" />
                        <label htmlFor="slides_6" />
                        <label htmlFor="slides_7" />
                        <label htmlFor="slides_8" />
                        <label htmlFor="slides_9" />
                        <label htmlFor="slides_10" />
                        <label className="goto-first" htmlFor="slides_1" />
                        <label className="goto-last" htmlFor="slides_10" />
                      </div>
                      <div className="navigation">
                        <div>
                          <label htmlFor="slides_1" />
                          <label htmlFor="slides_2" />
                          <label htmlFor="slides_3" />
                          <label htmlFor="slides_4" />
                          <label htmlFor="slides_5" />
                          <label htmlFor="slides_6" />
                          <label htmlFor="slides_7" />
                          <label htmlFor="slides_8" />
                          <label htmlFor="slides_9" />
                          <label htmlFor="slides_10" />
                        </div>
                      </div>
                    </div>
                  </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      <Footer
        image_src="/img/fff%20logo%20neu%20schwarz.svg"
        rootClassName="footer-root-class-name"
      ></Footer>
    </div>
  )
}

export default Home
