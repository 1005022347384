import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer'
import Header from '../components/header'
import './impressum.css'

const Impressum = (props) => {
  return (
    <div>
    <div className="impressum-container">
      <Helmet>
        <title>Impressum - FriedenFaktenFreiheit</title>
        <meta property="og:title" content="Impressum - FriedenFaktenFreiheit" />
      </Helmet>
      <div className="impressum-hero">
        <div className="impressum-fixed-header">
          <Header rootClassName="header-root-class-name1"></Header>
        </div>
      </div>
      <h1 className="impressum-text Text2XL">Impressum</h1>
      <div className="impressum-container1">
        <div className="impressum-story">
          <div className="impressum-container2">
            <div className="impressum-container3">
              <React.Fragment>
                <React.Fragment>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n    h2,h3, h4, p, li {\n    display: block;\n    width: 100%;\n    color: #888;\n    \n    }\n    h2, h3, h4 {\n    font-weight: 700;\n    margin: 1em 0 0;\n    }\n',
                    }}
                  />
                  <h2>Angaben gemäß § 5 TMG</h2>
                  <p>
                    Sebastian Verboket
                    <br />
                    High Road 120
                    <br />
                    East Finchley, England
                    <br />
                    N2 9ED London United Kingdom
                  </p>
                  <h2>Kontakt</h2>
                  E-Mail: <a href="mailto:faktenfriedenfreiheit@spost.eu">faktenfriedenfreiheit@spost.eu</a>
                  <p />
                  <h2>Redaktionell verantwortlich</h2>
                  <p>Betreiber der Webseite</p>
                  <p>
                    Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
                  </p>
                </React.Fragment>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      <div className="impressum-posts"></div>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default Impressum
