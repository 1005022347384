import React, { useEffect } from 'react';

const Menu = () => {
  useEffect(() => {

    const bodyOverflow = document.body.style.overflow;
    const getMenuElementsAndAddEventsByDataAttrs = (dataAttr) => {
      const allHeaders = document.querySelectorAll(`[role="Header"]`);
      allHeaders.forEach((header) => {
        const burgerBtn = header.querySelector(`[data-${dataAttr}="BurgerMenu"]`);
        const mobileMenu = header.querySelector(`[data-${dataAttr}="MobileMenu"]`);
        const closeBtn = header.querySelector(`[data-${dataAttr}="CloseMobileMenu"]`);

        if (!burgerBtn || !mobileMenu || !closeBtn) {
          return;
        }

        burgerBtn.addEventListener('click', () => {
          mobileMenu.classList.add('show');
        });

        closeBtn.addEventListener('click', () => {
          mobileMenu.classList.remove('show');
        });
      });
    };


    const getMenuElementsAndAddEvents = () => {
      const menuElements = document.querySelectorAll('.menu-burger');
      menuElements.forEach((burgerMenuElement) => {
        const mobileMenuElement = burgerMenuElement.nextElementSibling;

        if (!mobileMenuElement) {
          return;
        }

        const closeMenuElement = mobileMenuElement.querySelector('*[class*="menu-close"]');

        if (!closeMenuElement) {
          return;
        }

        burgerMenuElement.addEventListener('click', () => {
          mobileMenuElement.classList.add('show');
        });

        closeMenuElement.addEventListener('click', () => {
          mobileMenuElement.classList.remove('show');
        });
      });
    };

    const getNavbarElementsAndAddEventsByDatafffAttrs = () => {
      const allNavbars = document.querySelectorAll(`[data-role="navbar"]`);

      allNavbars.forEach((navbar) => {
        const burgerBtn = navbar.querySelector(`[data-role="burger-menu"]`);
        const mobileMenu = navbar.querySelector(`[data-role="mobile-menu"]`);
        const closeBtn = navbar.querySelector(`[data-role="close-menu"]`);

        if (!burgerBtn || !mobileMenu || !closeBtn) {
          return;
        }

        burgerBtn.addEventListener('click', () => {
          const checkSameLinkClicked = (event) => {
            // ... (unchanged)
          };

          window.addEventListener('click', checkSameLinkClicked);
          document.body.style.overflow = 'hidden';
          mobileMenu.classList.add('show', 'fff-show', 'fff-translate-to-default');
        });

        closeBtn.addEventListener('click', () => {
          document.body.style.overflow = bodyOverflow;
          mobileMenu.classList.remove('show', 'fff-show', 'translate-to-default');
        });
      });
            
    };

    getMenuElementsAndAddEventsByDataAttrs('type');
    getMenuElementsAndAddEventsByDataAttrs('role');
    getNavbarElementsAndAddEventsByDatafffAttrs();
    getMenuElementsAndAddEvents();

    // Cleanup function when the component unmounts
    return () => {
      // Remove any event listeners or perform cleanup if needed
    };
  }, []);

  return (
    <div>
    <a href="/" className="header-navlink">
    <img
      id="logo-claim"
      alt='Image'
      src='/img/fff%20logo%20neu%20weiss.svg'
      className="header-image"
    />
  </a>
  <a href="/profile" className="header-navlink1">
    Über mich
  </a>
  <a
    href="https://open.spotify.com/intl-de/artist/5NZVO7Xyv07VWlZIsPxDOW"
    target="_blank"
    rel="noreferrer noopener"
    className="header-link"
  >
    Musik
  </a>
  <a href="/#videos-sebbe" className="header-link1">
    Videos
  </a>
    </div>
  );
};
  
export default Menu;