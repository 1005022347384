import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Profile from './views/profile'
import Page404 from './views/page404'
import Home from './views/home'
import Datenschutz from './views/datenschutz'
import Impressum from './views/impressum'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Profile} exact path="/profile" />
        <Route component={Datenschutz} exact path="/datenschutz" />
        <Route component={Impressum} exact path="/impressum" />
        <Route component={Page404} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
